/* From Thymeleaf/Java frontend */
@import '../../../src/main/resources/css/utils.css';

/* ⇓⇓ Other custom utils go here ⇓⇓ */
@layer utilities {
  @responsive {
    /* Don't show up/down arrows inside of number input box on Firefox and WebKit-based browsers. */
    input[type='number'].no-spinner {
      -moz-appearance: textfield;
    }

    input.no-spinner::-webkit-outer-spin-button,
    input.no-spinner::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Auto-hide placeholder upon focus, taken from https://stackoverflow.com/a/11920714 */
    input.placeholder-focus-hide:focus::placeholder {
      color: transparent;
    }
    input.placeholder-focus-hide:focus::-webkit-input-placeholder {
      color: transparent;
    }
    input.placeholder-focus-hide:focus:-moz-placeholder {
      color: transparent;
    } /* FF 4-18 */
    input.placeholder-focus-hide:focus::-moz-placeholder {
      color: transparent;
    } /* FF 19+ */
    input.placeholder-focus-hide:focus:-ms-input-placeholder {
      color: transparent;
    } /* IE 10+ */

    /* Transform utility to flip an element vertically */
    .flip-vertical {
      transform: scaleY(-1);
    }
  }

  /* Highlighted element */
  .highlighted {
    @apply bg-yellow-500 px-1;
  }

  /* Disabled link */
  a.disabled {
    cursor: not-allowed;
  }

  /* Set dimensions for generic preview image larger screens */
  @screen md {
    .no-preview-image {
      width: 250px !important;
      height: 327px !important;
    }
  }
}
