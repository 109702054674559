/* Table of contents component and headline anchors */
#toc {
  @apply mb-6 py-2 px-4 border-l-8 border-theme-100 bg-theme-100 bg-opacity-25 rounded;
}

#toc a {
  @apply text-theme-900 text-sm;
}

#toc a:hover {
  @apply text-primary;
}

#toc a:focus {
  @apply text-primary;
}

#toc ul {
  @apply pl-4 list-none text-base;
}

#toc li {
  @apply relative font-bold;
}

#toc li::before {
  @apply absolute p-2 bg-no-repeat;
  content: " ";
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="%23E50015"><path transform="scale(-1 1) translate(-20 0)" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" /></svg>');
  top: 6px;
  left: -25px;
}

#toc li li {
  @apply font-light;
}

/* Anchor links */
.anchor {
  @apply block relative invisible;
  top: -160px;
}

.anchor + h1 > a > svg,
.anchor + h2 > a > svg,
.anchor + h3 > a > svg,
.anchor + h4 > a > svg,
.anchor + h5 > a > svg,
.anchor + h6 > a > svg {
  @apply align-baseline;
  width: 0.8em;
  height: 0.8em;
}

.anchor + h1:hover > a,
.anchor + h2:hover > a,
.anchor + h3:hover > a,
.anchor + h4:hover > a,
.anchor + h5:hover > a,
.anchor + h6:hover > a {
  @apply visible !important;
}

/* We need to override a few styles that Mirador 3 carries with it */
#viewer .mirador-osd-annotation-controls * {
  box-sizing: content-box;
}

/* Prevents scrolling the outside page when scrolled to the end of the sidebar content
   Does not work on Safari, unfortunately.
*/
#viewer aside .MuiPaper-root {
  overscroll-behavior: contain;
}

/* Fixes disappearing scrollbar in TOC */
#viewer ul.toc {
  width: 270px;
}

/* Fixes cutoff thumbnail labels */
#viewer .panel-thumbnail-view > ul.panel-listing-thumbs > li {
  margin-bottom: 11px !important;
}

#viewer .window-manifest-title {
  height: 1.25em;
}

#viewer .metadata-listing .metadata-item {
  padding-top: 5px;
}

#viewer .metadata-listing .metadata-label,
#viewer .metadata-listing .metadata-value {
  display: inline;
}

#viewer .metadata-listing .metadata-label {
  font-weight: bold;
  margin-right: 10px;
}

#viewer .metadata-listing img {
  display: block;
}

div.metadata-table div.metadata-label {
  text-align: right;
}

#viewer .metadata-item.within {
  display: none;
}

#viewer .window-manifest-navigation > .mirador-btn.mirador-icon-download {
  color: #929191;
  margin-right: 5px;
}

#viewer .window-manifest-navigation > .mirador-btn.mirador-icon-download:hover {
  color: black;
}

/* Table layout */
table {
  @apply min-w-full rounded table-auto;
}

tr:first-child {
  @apply bg-secondary font-bold;
}

tr:first-child p {
  @apply text-sm;
}

tr:nth-child(2n + 2) {
  @apply bg-theme-100 bg-opacity-50;
}

td, th {
  @apply px-4 py-2 border-2 border-white whitespace-no-wrap text-xs leading-5;
}

td p, th p {
  @apply text-xs;
}

td p:only-of-type, th p:only-of-type {
  @apply mb-0;
}

@screen md {
  table {
    @apply shadow-lg;
  }
}

td, th {
  @apply px-2 py-1 border-2 border-white whitespace-normal text-xs leading-5 hyphenate;
}

/* Mirador viewer */
h5.MuiTypography-h4 {
  @apply text-sm font-bold normal-case;
}

.mirador-label-value-metadata {
  @apply leading-snug;
}

.mirador-label-value-metadata dt {
  @apply mt-2 text-xs font-bold leading-snug;
}

.mirador-label-value-metadata dd {
  @apply pb-2 border-b border-theme-100 text-xs text-theme-600;
}

.mirador-label-value-metadata dd:last-child {
  @apply border-none;
}

.mirador-label-value-metadata dd > span {
  @apply block text-xs text-theme-600;
}

.MuiTypography-caption {
  @apply text-2xs !important;
}

.mirador-companion-window-title-controls > form {
  @apply pt-3;
}

.MuiBadge-dot {
  @apply bg-primary !important;
}

.mirador-next-canvas-button > .MuiIconButton-label,
.mirador-previous-canvas-button > .MuiIconButton-label {
  @apply bg-white rounded-full !important;
}

/* Viewer - search list */
.MuiListItemText-primary {
  @apply text-xs !important;
}

.MuiListItemText-primary > h6 {
  @apply mb-2 text-sm;
}

.MuiListItemText-primary > span {
  @apply text-xs text-theme-600;
}

.MuiListItemText-primary > strong {
  @apply text-xs;
}

.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  @apply border-r-4;
  background: none !important;
  border-right-color: #e50015;
}

/* Set background for higher contrast for better accessibility */
.mirador-companion-window-header {
  @apply bg-white border-b-4 border-double border-theme-100 !important;
}

/* Hide "About this item" header and accompanying button.
 * Does not hide the whole header element, since it also
 * contains tabs in the "ToC" Pane */
.mirador-companion-window-header > button {
  @apply hidden;
}

.mirador-companion-window-header > h3 {
  font-size: 0;
}

.mirador-companion-window-header > h3 > div {
  margin: 15px 12px 0 0;
}

.mirador-companion-window-header > h3 > div > span {
  @apply text-xs leading-none;
}

/* Viewer - Sidepanel */
@media (max-width: 600px) {
  .mirador-companion-area-left > .mirador-companion-windows,
  .mirador-companion-windows > aside,
  .mirador-companion-windows > aside > div:first-child {
    /* Side panel width equal to breakpoint minus icon panel width */
    width: calc(100vw - 48px) !important;
    background-color: #fff;
  }
  .MuiToolbar-regular,
  .MuiToolbar-regular > h3 {
    display: block;
    width: 100%;
  }

  table tr {
    @apply bg-theme-100 bg-opacity-50;
    display: block;
  }

  table tr:not(:last-of-type) {
    @apply mb-4;
  }

  table tr:first-of-type {
    display: none;
  }

  table td {
    @apply border-b border-theme-100;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    text-align: right;
    width: 100%;
  }

  table td::before {
    @apply font-bold mr-1;
    content: attr(data-label);
    flex-shrink: 0;
    text-align: left;
    width: 50%;
  }
}

/* Set outline for focused object container */
.openseadragon-canvas:focus {
  @apply outline-none;
  box-shadow: inset 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
}

/* To top scroller */
#to-top-scroller {
  bottom: 30px;
  right: 30px;
  z-index: 10000;
}

/* Timeline */
#timeline ul {
  @apply block relative mt-24 mx-auto text-xs;
}

#timeline ul.pl-6 {
  @apply pl-0 !important;
}

#timeline ul::after {
  @apply absolute top-0 bottom-0 bg-theme-900;
  content: "";
  width: 6px;
  left: 30px;
  margin-left: -3px;
}

#timeline li {
  @apply block relative py-2 list-none;
}

#timeline li > p {
  @apply relative m-0 px-6 pt-4 pb-6 bg-secondary rounded break-words;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

#timeline li > p > strong {
  @apply inline-block text-xl mb-2 font-serif;
}

/* Full-width containers */
#timeline li:nth-child(odd),
#timeline li:nth-child(even) {
  @apply w-full pl-16 pr-0;
}

/* The circles on the vertical ruler */
#timeline li:nth-child(odd)::after,
#timeline li:nth-child(even)::after {
  @apply absolute w-5 h-5 border-4 border-theme-900 bg-white rounded-full z-10;
  content: "";
  left: 18px;
  top: 19px;
}

/* Make sure that all arrows are pointing leftwards */
#timeline li:nth-child(odd)::before,
#timeline li:nth-child(even)::before {
  @apply absolute w-0 h-0 z-10;
  content: " ";
  left: 64px;
  top: 22px;
  right: 25px;
  border-width: 10px 10px 10px 0;
  border-color: transparent #e5b617 transparent transparent;
}

@screen md {
  /* Center the timeline */
  #timeline ul::after {
    left: 50%;
  }

  /* Alternate containers */
  #timeline li:nth-child(even) {
    @apply w-1/2 my-0 mr-0 ml-auto pl-8 pr-0;
  }

  #timeline li:nth-child(odd) {
    @apply w-1/2 pl-0 pr-8;
  }

  /* Add arrows to the left container (pointing right) */
  #timeline li:nth-child(odd)::before {
    left: auto;
    right: 25px;
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent #e5b617;
  }

  /* Add arrows to the right container (pointing left) */
  #timeline li:nth-child(even)::before {
    left: 25px;
    border-width: 10px 10px 10px 0;
    border-color: transparent #e5b617 transparent transparent;
  }

  /* Place circles on timeline */
  #timeline li:nth-child(odd)::after {
    left: auto;
    right: -12px;
  }

  #timeline li:nth-child(even)::after {
    left: -12px;
    right: auto;
  }
}

/* FAQ accordion */
#accordion #toc {
  @apply hidden;
}

#accordion .anchor {
  @apply m-0 invisible;
}

#accordion h2 {
  @apply mt-12 mb-4;
}

#accordion h3 {
  @apply relative cursor-pointer mb-0 py-4 pr-12 border-b border-theme-100 font-bold font-sans text-base;
  transition: 0.4s;
}

#accordion h3:focus {
  @apply outline-none shadow-outline;
}

#accordion h3:not(.active):hover,
#accordion h3:not(.active):focus {
  @apply text-theme-600;
  transition: 0.4s;
}

#accordion h3::after {
  @apply absolute top-0 right-0 pt-4;
  content: "+";
  transition: 0.4s;
}

#accordion h3.active {
  @apply bg-theme-100 pl-4 border-white;
}

#accordion h3.active::after {
  @apply pr-4;
  content: "\2012";
  transition: 0.4s;
}

#accordion h3.active + ul {
  @apply border-l border-r border-b-4 border-theme-100 mb-4;
}

#accordion h3 + ul {
  @apply overflow-hidden list-none px-4 !important;
  max-height: 0;
  transition: 0.4s, max-height 0.2s ease-out;
}

#accordion h3 + ul > li {
  @apply pt-4 pb-6;
}

@screen lg {
  #accordion h3 {
    @apply pl-4;
  }

  #accordion h3,
  #accordion h3 + ul {
    @apply -mx-4;
    width: calc(100% + 2rem);
  }

  #accordion h3::after {
    @apply pr-4;
  }
}

#accordion button > svg {
  height: 0.8em !important;
  vertical-align: baseline;
  width: 0.8em !important;
}

#accordion button:hover > svg {
  @apply visible !important;
}

/* Help popover set different external-link-icon color */
.help-popover a[href^="http"]:not(.no-ext-link)::after {
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" fill="%23FFFFFF"><path d="M0 0h22v22H0z" fill="none"/><path d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"/></svg>');
}

/* Make icon in Mirador workspace control panel not overlap */
.mirador-workspace-control-panel a[href^="http"]:not(.no-ext-link)::after {
  font-size: 11px;
  margin-top: 25px;
  margin-left: -22px;
}

/* Set Colors in contact map leaflet for higher contrast */
#contact-map .leaflet-bottom.leaflet-right {
  @apply bg-white;
}

#contact-map .leaflet-bottom.leaflet-right a {
  @apply text-primary;
}
