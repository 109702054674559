main * {
  /* Account for sticky header */
  scroll-margin-top: 8rem;
}

h1, h2, h3, h4, h5, h6 {
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

h1 {
  @apply mt-8 mb-4 text-3xl font-serif font-bold leading-tight break-words;
}

h2 {
  @apply mt-8 mb-3 text-xl font-serif font-bold leading-tight break-words;
}

h3 {
  @apply mb-6 mb-2 text-base font-serif font-bold leading-snug break-words;
}

h4 {
  @apply mb-6 mb-2 text-sm font-serif font-bold leading-normal break-words;
}

h5 {
  @apply mb-6 mb-2 text-sm leading-normal uppercase break-words;
}

h6 {
  @apply mb-6 mb-2 text-xs font-bold leading-normal break-words;
}

code {
  padding: 2px 4px;
  border-radius: 4px;
  font-size: 90%;
  background: #FCE6E8;
  color: #c7254e;
}

main {
  @apply text-sm;
}

p {
  @apply mb-6;
}

a {
  @apply text-primary;
}

a:hover,
a:focus {
  @apply underline;
}

a:focus,
input:focus,
button:focus {
  @apply outline-none shadow-outline;
}

ol {
  @apply pl-8;
}

ol > li {
  @apply my-4;
}

ul + * {
  @apply mt-6;
}

li > *:last-child {
  @apply mb-0;
}

::placeholder {
  font-style: italic;
  opacity: 1;
}

/* Scrollbars */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  @apply bg-theme-100;
}

::-webkit-scrollbar-thumb {
  @apply bg-theme-500;
}

::-webkit-scrollbar-thumb:hover {
  @apply bg-theme-700;
}

/* External link icon */
a[href^="http"]:not(.no-ext-link) img,
a[href^="http"]:not(.no-ext-link) figure {
  @apply inline;
}

a[href^="http"]:not(.no-ext-link)::after,
a[href^="whatsapp"]:not(.no-ext-link)::after {
  @apply inline-block mt-px bg-no-repeat bg-cover leading-none align-baseline;
  content: "";
  margin-left: 3px;
  padding-right: 1em;
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" fill="%23E50015"><path d="M0 0h22v22H0z" fill="none"/><path d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"/></svg>');
  height: 1em;
  width: 1em;
}

/* Responsive */
@screen md {
  main {
    @apply text-base;
  }

  h1 {
    @apply mt-10 mb-6 text-4xl;
  }

  h2 {
    @apply text-2xl;
  }

  h3 {
    @apply text-xl;
  }
}

@screen lg {
  h1 {
    @apply mt-16;
  }
}
