/* Text overflow */
.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.hyphenate {
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto
}

/* For extended search area on large screens */
.rounded-b-xl {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

/* Frontpage headings */
.intro > p {
  @apply leading-snug;
}

.frontpage-h2 {
  @apply mt-8 mb-4 text-3xl text-center font-serif font-bold leading-tight break-words;
}

.frontpage-h2::before {
  content: " ";
  @apply block w-12 h-1 mb-3 mx-auto bg-primary;
}

@screen md {
  .frontpage-h2 {
    @apply mt-10 mb-6 text-4xl;
  }

  .frontpage-h2::before {
    @apply mb-1;
  }
}

@screen lg {
  .frontpage-h2 {
    @apply mt-16;
  }
}

/* Frontpage full width container */
.full-width-container  {
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

/* tiny-slider */
.tns-visually-hidden {
  position: absolute;
  left: -10000em;
}

#highlights .tns-nav {
  height: 0;
}

#highlights .highlights-controls > button[disabled] {
  @apply hidden;
}

#selected-collections .tns-nav {
  position: absolute;
  bottom: -32px;
  margin-left: -10px;
  width: 100%;
  text-align: center;
  line-height: 0;
}

#selected-collections .tns-nav > [aria-controls] {
  @apply w-3 h-3 mx-1 bg-theme-200 rounded-full;
}

#selected-collections .tns-nav > .tns-nav-active {
  @apply bg-theme-600;
}

#selected-collections .collections-controls > li[aria-disabled="true"] {
  @apply text-theme-200 cursor-not-allowed !important;
}

/* Hero heading on startpage */
.intro h2 {
  @apply mt-0 mb-3 text-3xl font-serif font-bold leading-tight;
}

.intro h3 {
  @apply mt-0 mb-6 text-xl font-normal font-sans leading-snug;
}

/* Spinner fadein/fadeout on search page */
.spinner {
  -webkit-animation: fadein 3s ease 0s normal forwards;
  animation: fadein 3s ease 0s normal forwards;
  opacity: 1; /* fallback */
}

.spinner.fadeout {
  -webkit-animation: fadeout 1s ease 0s normal forwards;
  animation: fadeout 1s ease 0s normal forwards;
}

@-webkit-keyframes fadein {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fadein {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-webkit-keyframes fadeout {
  0% { opacity: 1; }
  100% { opacity: 0; visibility: hidden; }
}

@keyframes fadeout {
  0% { opacity: 1; }
  100% { opacity: 0; visibility: hidden; }
}

/* Adjust link color on theme-100 using contrast class */
.contrast a {
  color: #D0151E;
}

.contrast a[href^="http"]:not(.no-ext-link)::after {
  background-image:
    url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" fill="%23D0151E"><path d="M0 0h22v22H0z" fill="none"/><path d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"/></svg>');
}

/* various */
p:only-of-type {
  @apply mb-0;
}

.scan-scene {
  box-shadow: inset 0 0 30px 0 rgba(0,0,0,0.5);
}

.full-metadata a[href^="http"]::after {
  background-position: 0;
}

.sitemap .caret-icon {
  @apply -ml-1;
  top: 6px;
}