/* From Thymeleaf/Java frontend */
@import "../../../src/main/resources/css/components.css";

/* ⇓⇓ Other custom components go here ⇓⇓ */

/* Small tick in lower left corner for bars in date facet histogram */
@layer components {
  .bar-with-label:after {
    border-left: 1px solid #bdbdbd;
    content: '';
    left: -1px;
    height: 5px;
    position: absolute;
    top: 100%;
  }

  /* Override min width of date input fields */
  .react-date-picker__inputGroup {
    min-width: 0 !important;
  }

  .react-date-picker__calendar {
    z-index: 50 !important;
  }

  /* Adjust hover and focus color for better accessibility */
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background-color: #096FFA !important;
  }
}
